import React from 'react'

const IndustryWeServe = () => {
  return (
    <>
    <div className="service bg_grad py_100 position-relative">
    <div className="container-md">
      <div className="row justify-content-center">
        <div className="col-xl-6 col-lg-8 col-md-10">
          <div className="text-center mb-5">
            <h2 className="fs-1 fw-bold wow fadeInUp">Industry <span className="animate-text">We Serve</span></h2>
            <p className="wow fadeInUp" data-wow-delay="0.2s">Bridge Your Business with the Virtual World</p>
          </div>
        </div>
      </div>
      <div className="row g-3">
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover">
            <img src="img/industry-img/automotive.png" className="position-relative" alt="Automotive" />
            <div className="mt-3 fs-17 position-relative">FinTech</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="0.3s">
            <img src="img/industry-img/healthcare.png" className="position-relative" alt="Healthcare" />
            <div className="mt-3 fs-17 position-relative">Healthcare</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="0.4s">
            <img src="img/industry-img/ecommerce.png" className="position-relative" alt="eCommerce" />
            <div className="mt-3 fs-17 position-relative">E-Commerce</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="0.5s">
            <img src="img/industry-img/automotive.png" className="position-relative" alt="Automotive" />
            <div className="mt-3 fs-17 position-relative">Automotive</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="0.6s">
            <img src="img/industry-img/education.png" className="position-relative" alt="Education" />
            <div className="mt-3 fs-17 position-relative">Education</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="0.7s">
            <img src="img/industry-img/real-estate.png" className="position-relative" alt="Real Estate" />
            <div className="mt-3 fs-17 position-relative">Real-Estate</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="0.8s">
            <img src="img/industry-img/hospitality.png" className="position-relative" alt="Hospitality" />
            <div className="mt-3 fs-17 position-relative">Hospitality</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="0.9s">
            <img src="img/industry-img/rmg.png" className="position-relative" alt="RMG" />
            <div className="mt-3 fs-17 position-relative">RMG</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="1s">
            <img src="img/industry-img/pharmacy.png" className="position-relative" alt="Pharmacy" />
            <div className="mt-3 fs-17 position-relative">Pharmacy</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="1.1s">
            <img src="img/industry-img/aviation.png" className="position-relative" alt="Aviation" />
            <div className="mt-3 fs-17 position-relative">Aviation</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="1.2s">
            <img src="img/industry-img/ui-ux.png" className="position-relative" alt="UI/UX" />
            <div className="mt-3 fs-17 position-relative">UI/Ux</div>
          </div>
        </div>
        <div className="col-xl-2 col-md-4 col-sm-6">
          <div className="bg-white d-block py-4 text-center wow fadeIn product_link bgChangeOnHover" data-wow-delay="1.3s">
            <img src="img/industry-img/more.png" className="position-relative" alt="More" />
            <div className="mt-3 fs-17 position-relative">Many More</div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default IndustryWeServe