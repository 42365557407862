import React from 'react'
import { Link } from 'react-router-dom'

const ClientsLogo = () => {
  return (
    <>
    <div className="py_100">
    <div className="container-md">
      <div className="row mb_100 justify-content-center">
        <div className="col-xl-6 col-lg-8 col-md-10">
          <div className="text-center mb-4">
            <h4 className="fs-17">NikunjERP, Inc.</h4>
            <h2 className="fs-1 fw-bold mb-3">Our <span className="text-nikunjerp animate-text"> Clients</span></h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <img src="img/nikunjerp-clients.png" className="img-fluid" alt="NikunjERP Map" />
        </div>
      </div>
      {/* <div className="align-items-center justify-content-center row text-center clients_logo">
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/web-logo-ILO.png" className="client_logo transition" alt="Web Logo ILO" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/ict.png" className="client_logo transition" alt="ICT" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/hameemgroup-logo.png" className="client_logo transition" alt="Hameem Group" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/APSCL.png" className="client_logo transition" alt="APSCL" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/ahossain.png" className="client_logo transition" alt="Ahossain" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/wasa.png" className="client_logo transition" alt="Dhaka Wasa" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/siglo-xxi.png" className="client_logo transition" alt="Siglo XXI" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/citzim.png" className="client_logo transition" alt="citzim" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/milk-vita2.png" className="client_logo transition" alt="milk vita" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/sa-agro.png" className="client_logo transition" alt="sa agro" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <img src="img/global-clients/gdm.png" className="client_logo transition" alt="GDM" />
          </div>
        </div>
        <div className="align-items-center col-xl-2 col-sm-4 col-6 d-flex logo_box justify-content-center">
          <div className="d-block">
            <a href="" className="btn btn-dark px-5 py-3">More</a>
          </div>
        </div>
      </div> */}
      <div className="row">
        <div className="col-xl-10 offset-xl-1">
          <div className="align-items-center bg-dark d-xl-flex justify-content-between py-5 rounded-5 join_box mt-5">
            <div className="d-block mw_560">
              <h3 className="fs-3 fw-bold mb-2 text-white">Be with Us to Wheel Up Your Revenue</h3>
              <p className="text-white mb-0">We are always ready to serve you. Please share your ideas and values with us.</p>
            </div>
            <div className="d-block mt-4 mt-xl-0">
              <Link to="/contact" className="btn btn-success px-5 py-3">Contact Us</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    </>
  )
}

export default ClientsLogo