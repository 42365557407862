import React from 'react'
import { BsInstagram } from 'react-icons/bs'
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa'
import { TfiYoutube } from 'react-icons/tfi'

const Footer = () => {
  return (
    <>
     <footer className="position-relative">
    <div className="footer_shape">
      <img src="img/footer-bg.webp" className="w-100" alt />
    </div>
    <div className="footer_wrapper">
      <div className="container-md">
        <div className="row pb-5 pt_80 bottom_line position-relative">
          <div className="col-xl-4 footer-column">
            <ul className="nav flex-column mb-5 mb-xl-0">
              <li className="nav-item mb-4">
                <a className href="">
                  <img src="img/chiranjeeviBhav-logo.webp" alt="NikunjERP Logo White" />
                </a>
              </li>
              <li className="nav-item">
                <p className="text-muted w-75">Best Software Development Company To Architect The Dream For Next Generation.</p>
              </li>
              <li className="nav-item">
                <div className="align-items-center d-flex">
                  <div className="me-4">
                    <div className="fs-4 fw-light mb-1 text-white">10,000+</div>
                    <p className="text-muted mb-0">items sold</p>
                  </div>
                  <div className>
                    <div className="fs-4 fw-light mb-1 text-white">40+</div>
                    <p className="text-muted mb-0">Products</p>
                  </div>
                </div>
              </li>
              <li className>
                <ul className="d-flex list-unstyled mt-4">
                <li><a className="align-items-center d-flex justify-content-center me-2 text-decoration-none text-white social_icon" href="" title="Facebook" target="_blank"><FaFacebookF /></a></li>
                <li><a className="align-items-center d-flex justify-content-center me-2 text-decoration-none text-white social_icon" href="" title="Instagram" target="_blank"><BsInstagram /></a></li>
                <li><a className="align-items-center d-flex justify-content-center me-2 text-decoration-none text-white social_icon" href="" title="Linkedin" target="_blank"><FaLinkedinIn /></a></li>
                <li><a className="align-items-center d-flex justify-content-center me-2 text-decoration-none text-white social_icon" href="" title="Youtube" target="_blank"><TfiYoutube /></a></li>
                <li><a className="align-items-center d-flex justify-content-center me-2 text-decoration-none text-white social_icon" href="" title="Twitter" target="_blank"><FaTwitter /></a></li>
              
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-xl-3 col-md-6 footer-column">
            <ul className="nav flex-column">
              <li className="nav-item mb-4">
                <span className="d-block footer-title fs-17 pb-2 text-white">Address</span>
              </li>
              <li className="nav-item">
                <p className="text-muted fw-bold mb-2">Idian Office</p>
              </li>
              <li className="nav-item">
                <p className="text-muted mb-4">Babail Road ,Near [Chhotu Ram Chowk, Gyandeep School], Panipat, Haryana-132103</p>
              </li>
              
              <li className="nav-item mb-2">
                <p className="text-muted mb-0">Email:</p>
              </li>	
              <li className="nav-item">
                <p className="text-muted mb-0">info@chiranjeevibhav.com</p>
              </li>
            </ul>
          </div>
          <div className="col-xl-4 offset-xl-1 col-md-6 footer-column">
            <ul className="nav flex-column">
              <li className="nav-item mb-4">
                <span className="d-block footer-title fs-17 pb-2 text-white">Phone</span>
              </li>
            </ul>
            <div className="row">
              <div className="col-6">
                <ul className="nav">
                  <li className="nav-item">
                    <p className="text-muted mb-2">Service:</p>
                    <p className="text-muted">+91-9034905402</p>
                  </li>
                </ul>
              </div>
              <div className="col-6">
                <ul className="nav">
                  <li className="nav-item">
                    <p className="text-muted mb-2">Office Reception:</p>
                    <p className="text-muted">+91-9034905402</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row py-5 d-none d-xl-flex">
          <div className="col-md-3">
            <ul className="nav flex-column">
              <li className="nav-item mb-4">
                <span className="footer-title text-white">Disclaimer</span>
              </li>
              <li>
                <p className="text-muted">© 2024 NikunjERP. All Rights Reserved.</p>
                <p className="text-muted">Here all software, apps, themes, plugins, are our own property. Therefore copying or reselling is strictly prohibited.</p>
              </li>
            </ul>
          </div>
          <div className="col-lg-8 offset-lg-1">
            <div className="row">
              <div className="col-md-4">
                <ul className="nav flex-column">
                  <li className="nav-item mb-4">
                    <span className="footer-title text-white">Useful Links</span>
                  </li>
                  <li className="nav-item">
                    <a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>About Us</a>
                  </li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30" ><span className="arrow me-2">→</span>Blog</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30" ><span className="arrow me-2">→</span>Career - We Are Hiring</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30" ><span className="arrow me-2">→</span>Terms of Use</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30" ><span className="arrow me-2">→</span>Terms of Use</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30" ><span className="arrow me-2">→</span>Privacy Policy</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30" ><span className="arrow me-2">→</span>Copyright</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30" ><span className="arrow me-2">→</span>Client List</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30" ><span className="arrow me-2">→</span>Affiliate Disclosure</a></li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="nav flex-column">
                  <li className="nav-item mb-4">
                    <span className="footer-title text-white">Services</span>
                  </li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>Custom Software</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>Graphics Design</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>Clipping Path</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>Digital Health</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>SaaS Model</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>NikunjERP Academy</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>Video Production Service</a></li>
                  <li className="nav-item"><a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>Digital Marketing Agency in India</a></li>
                </ul>
              </div>
              <div className="col-md-4">
                <ul className="nav flex-column">
                  <li className="nav-item mb-4">
                    <span className="footer-title text-white">Support</span>
                  </li>
                  <li className="nav-item">
                    <a href="contact" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>Contact Us</a>
                  </li>
                  <li className="nav-item">
                    <a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>Help Center</a>
                  </li>
                  <li className="nav-item">
                    <a href="" className="text-decoration-none text-muted lh-30"><span className="arrow me-2">→</span>Item Support</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <div className="footer_bottom py-3">
    <div className="container">
      <div className="align-items-center justify-content-between row">
        <div className="col-lg-6">
          <div className="align-items-center d-flex flex-wrap justify-content-center justify-content-lg-start mb-4 mb-lg-0">
            <div className="mx-2">
              <img src="asset/img/iso.png" alt="ISO Certificate" />
            </div>
            <a href="" rel="nofollow" className="mx-2" >
              <img src="asset/img/basis.png" alt="Basis Certificate" />
            </a>
            
          </div>
        </div>
        <div className="col-lg-6">
          <div className="align-items-center d-flex justify-content-center justify-content-lg-end">
            <div><h6 className="mb-0 me-3 text-muted">Open</h6></div>
           </div>
        </div>
      </div>
    </div>
  </div>

  <a className="gotoTop position-fixed rounded-2 text-center text-decoration-none text-white transition">
    <i className="ti-arrow-up" />
  </a>
    </>
  )
}

export default Footer