import Contact from "./Components/Contact";
import Home from "./Components/Home";
import { Routes, Route } from "react-router-dom";
import IndustryWeServe from "./Components/IndustryWeServe";
import Footer from "./Components/Footer";
import Service from "./Components/Service";
import Header from "./Components/Header";
import ClientsLogo from "./Components/SubCompo/ClientsLogo";

function App() {
  return (
    <div>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/industryweserve" element={<IndustryWeServe />} />
      <Route path="/header" element={<Header />} />
      <Route path="/footer" element={<Footer />} />
      <Route path="/service" element={<Service />} />
      <Route path="/clientlogo" element={<ClientsLogo />} />
     

    </Routes>
    </div>
  );
}

export default App;
