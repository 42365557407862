import React from 'react'

const Whatsapp_Teligram = () => {
  return (
    <>
    <div className="align-items-center flex-wrap g-3 position-fixed row sideLink top-50 translate-middle-y">
    <a href="https://api.whatsapp.com/send?phone=+9034905402&text=Hello%21%20Would%20you%20like%20to%20help%20me?" target="_blank" rel="nofollow" className="scl_link whatsapp_link" >
      <img src="assets/images/icons/whatsapp.png" alt="WhatsApp" />
    </a>
    <a title="Telegram" href="" rel="nofollow" className="scl_link telegram_link">
      <img src="assets/images/icons/telegram.png" alt="Telegram" />
    </a>
  </div>
    </>
  )
}

export default Whatsapp_Teligram