import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <>
    <header className="d-flex flex-wrap justify-content-center py-4 position-relative">
          <Link to="/" className="d-flex align-items-center mb-0 me-auto text-dark text-decoration-none">
            <img src="img/chiranjeeviBhav-logo.webp" alt="ChiranjeeviBhav Logo" />
          </Link>
          <ul className="nav nav-pills d-none d-lg-flex">
            <li className="nav-item dropdown mega-dropdown" id="mega-dropdown-menu">
              <a href="javascript:void(0);" className="mega-dropdown-toggle nav-link position-relative" data-toggle="dropdown" aria-expanded="true">All Products</a>
              <div className="dropdown-menu end-0 mega-dropdown-menu start-0 bg-white">
                <div className="row">
                  <div className="col-md-3">
                    <ul className="nav nav-pills flex-column" id="pills-megaMenu" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-business-tab" data-bs-toggle="pill" data-bs-target="#pills-business" type="button" role="tab" aria-controls="pills-business" aria-selected="true">Business Solution</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-blockchain-tab" data-bs-toggle="pill" data-bs-target="#pills-blockchain" type="button" role="tab" aria-controls="pills-blockchain" aria-selected="false">Blockchain</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-hotel-restaurant-tab" data-bs-toggle="pill" data-bs-target="#pills-hotel-restaurant" type="button" role="tab" aria-controls="pills-hotel-restaurant" aria-selected="false">Hotel &amp; Restaurant</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-ecommerce-tab" data-bs-toggle="pill" data-bs-target="#pills-ecommerce" type="button" role="tab" aria-controls="pills-ecommerce" aria-selected="false">Ecommerce</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-education-tab" data-bs-toggle="pill" data-bs-target="#pills-education" type="button" role="tab" aria-controls="pills-education" aria-selected="false">Education</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-health-tab" data-bs-toggle="pill" data-bs-target="#pills-health" type="button" role="tab" aria-controls="pills-health" aria-selected="false">Health</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-news-portal-tab" data-bs-toggle="pill" data-bs-target="#pills-news-portal" type="button" role="tab" aria-controls="pills-news-portal" aria-selected="false">News Portal</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-online-reservation-tab" data-bs-toggle="pill" data-bs-target="#pills-online-reservation" type="button" role="tab" aria-controls="pills-online-reservation" aria-selected="false">Online Reservation</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-template-tab" data-bs-toggle="pill" data-bs-target="#pills-template" type="button" role="tab" aria-controls="pills-template" aria-selected="false">Template</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-custom-tab" data-bs-toggle="pill" data-bs-target="#pills-custom" type="button" role="tab" aria-controls="pills-custom" aria-selected="false">Custom Service</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-others-tab" data-bs-toggle="pill" data-bs-target="#pills-others" type="button" role="tab" aria-controls="pills-others" aria-selected="false">Others</button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-9">
                    <div className="tab-content" id="pills-megaMenuContent">
                      <div className="tab-pane fade show active" id="pills-business" role="tabpanel" aria-labelledby="pills-business-tab">
                        <div className="row g-3">
                          <div className="col-xl-4 col-md-6">
                            <a href=""  className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Business-solution/HR-Manager.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>HR Manager</h6>
                                <p className="mb-0 fs-14">Human Resource Management</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href=""  className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Business-solution/Sales-ERP.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>ERP</h6>
                                <p className="mb-0 fs-14">Business ERP Solution / Company Management</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href=""  className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Business-solution/xM-store.webp.pagespeed.ic.Jx_qtbfMxk.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>M-Store</h6>
                                <p className="mb-0 fs-14">Multi-Store Inventory Management</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href=""  className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Business-solution/xWholesale.webp.pagespeed.ic.jR2MRJl-Vg.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Wholesale</h6>
                                <p className="mb-0 fs-14">Inventory Management System</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href=""  className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Business-solution/xInnue.webp.pagespeed.ic.EhaEbAdXKB.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Innue</h6>
                                <p className="mb-0 fs-14">Business Live Chatbot Software</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-blockchain" role="tabpanel" aria-labelledby="pills-blockchain-tab">
                        <div className="row g-3">
                          <div className="col-lg-4">
                            <a href=""  className="align-items-start bg_grad2 d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Blockchain/NFTBox.webp.pagespeed.ce.Iq02Y_uxMA.html" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>NFTBOX</h6>
                                <p className="mb-0 fs-13">NFT Marketplace Script</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href=""  className="align-items-start bg_grad2 d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Blockchain/Tradebox.webp.pagespeed.ce.xXKpZDLtmQ.html" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Tradebox</h6>
                                <p className="mb-0 fs-13">CryptoCurrency Buy Sell and Trading Software</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href=""  className="align-items-start bg_grad d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Blockchain/Nisshue.webp.pagespeed.ce.IuFIraF5nZ.html" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Nishue</h6>
                                <p className="mb-0 fs-13">CryptoCurrency Buy Sell with MLM System</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href=""  className="align-items-start bg_grad2 d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Blockchain/ICO-Wallet.webp.pagespeed.ce.MBtissXkud.html" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>ICO Wallet</h6>
                                <p className="mb-0 fs-13">ICO Software and Token Launching Solution</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href=""  className="align-items-start bg_grad d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Blockchain/Tokenbox.webp.pagespeed.ce.rB9CNLYR5q.html" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Tokenbox</h6>
                                <p className="mb-0 fs-13">Best Security Token Offering Platform (STO)</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-ecommerce" role="tabpanel" aria-labelledby="pills-ecommerce-tab">
                        <div className="row g-3">
                          <div className="col-lg-4">
                            <a href=""  className="align-items-start bg_grad d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Ecommerce/xIsshue.webp.pagespeed.ic.f5QQ2rI5Hd.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Isshue</h6>
                                <p className="mb-0 fs-13">Multi Store eCommerce Shopping Cart Solution</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href=""  className="align-items-start bg_grad2 d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Ecommerce/xStorefex.webp.pagespeed.ic.C7rSxSToXJ.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Storefex</h6>
                                <p className="mb-0 fs-13">Multi Vendor Marketplace Platform</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-education" role="tabpanel" aria-labelledby="pills-education-tab">
                        <div className="row g-3">
                          <div className="col-lg-4">
                            <a href="" className="align-items-start bg_grad d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Education/xQuizguru.webp.pagespeed.ic.NcxkdXpyuV.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Quizguru</h6>
                                <p className="mb-0 fs-13">Online Exam System PHP Script</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href="" className="align-items-start bg_grad2 d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Education/Edutask.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Edutask</h6>
                                <p className="mb-0 fs-13">Online Course Selling Marketplace</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-health" role="tabpanel" aria-labelledby="pills-health-tab">
                        <div className="row g-3">
                          <div className="col-lg-4">
                            <a href="" className="align-items-start bg_grad d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Health/Hospital-Automanager.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Hospital Automanager</h6>
                                <p className="mb-0 fs-13">Advance Hospital Management System Software</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href="" className="align-items-start bg_grad2 d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Health/Hospital.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Hospital</h6>
                                <p className="mb-0 fs-13">Hospital Management System</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href="" className="align-items-start bg_grad d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Health/Pharmacare.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Pharmacare</h6>
                                <p className="mb-0 fs-13">Pharmacy Management System</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href="" className="align-items-start bg_grad2 d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Health/Multi-Hospital.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Multi-Hospital</h6>
                                <p className="mb-0 fs-13">Best Hospital Management System (SaaS App)</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href="" className="align-items-start bg_grad d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Health/Doctorss.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Doctorss</h6>
                                <p className="mb-0 fs-13">Doctor Appointment and Prescription System</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href="" className="align-items-start bg_grad2 d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Health/G-Prescription.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>G-Prescription</h6>
                                <p className="mb-0 fs-13">Gynaecology &amp; OBS Consultation Software</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-lg-4">
                            <a href=""  className="align-items-start bg_grad d-flex p-3 product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Health/Clinic-365.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-3">
                                <h6>Clinic365</h6>
                                <p className="mb-0 fs-13">Clinic Management System</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-news-portal" role="tabpanel" aria-labelledby="pills-news-portal-tab">
                        <div className="row g-3">												
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/News-Portal/News-365.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>News365</h6>
                                <p className="mb-0 fs-14">PHP Newspaper Script Magazine Blog with Video Newspaper</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/News-Portal/Osru.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Osru</h6>
                                <p className="mb-0 fs-14">Auto News Post and Manual PHP Newspaper Script | Auto Facebook Post</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-online-reservation" role="tabpanel" aria-labelledby="pills-online-reservation-tab">
                        <div className="row g-3">												
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Online-Reservation/VMS.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>VMS</h6>
                                <p className="mb-0 fs-14">Vehicle Management System</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Online-Reservation/GMS.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>GMS</h6>
                                <p className="mb-0 fs-14">Garage Management Software</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Online-Reservation/Bus-365.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Bus365</h6>
                                <p className="mb-0 fs-14">Bus Reservation System</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-hotel-restaurant" role="tabpanel" aria-labelledby="pills-hotel-restaurant-tab">
                        <div className="row g-3">												
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Hotel-Restaurant/Bhojon.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Bhojon</h6>
                                <p className="mb-0 fs-14">Restaurant Management Software</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Hotel-Restaurant/Cloud-KItchen.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Cloud Kitchen</h6>
                                <p className="mb-0 fs-14">Kitchen Management Software</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Hotel-Restaurant/Xain.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Xain Hotel</h6>
                                <p className="mb-0 fs-14">Hotel Management System &amp; Booking Software</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-template" role="tabpanel" aria-labelledby="pills-template-tab">
                        <div className="row g-3">												
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Template/CRM-Admin.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>CRM Admin</h6>
                                <p className="mb-0 fs-14">Bootstrap CRM Admin Template Dashboard</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Template/Healtha-Admin.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Health Admin</h6>
                                <p className="mb-0 fs-14">Bootstrap Health Admin Template Dashboard</p>
                              </div>
                            </a>
                          </div>											
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Template/HMVC-Admin.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>HMVC Admin</h6>
                                <p className="mb-0 fs-14">Dynamic Admin Panel</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Template/Admin-Page.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Adminpage</h6>
                                <p className="mb-0 fs-14">Responsive Bootstrap Admin Template</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Template/Material-UI.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Material UI</h6>
                                <p className="mb-0 fs-14">Material Design Admin Template Dashboard</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Template/Travel-Tour.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Travel Tour</h6>
                                <p className="mb-0 fs-14">Tour Travel Hotel Booking HTML Template</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Template/Osru.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Osru</h6>
                                <p className="mb-0 fs-14">News, Blog &amp; Magazine HTML Template</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Template/Arshi.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Arshi</h6>
                                <p className="mb-0 fs-14">Multipurpose All in one HTML Template</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-custom" role="tabpanel" aria-labelledby="pills-custom-tab">
                        <div className="row g-3">
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Custom-Service/Banking-Solution.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Banking</h6>
                                <p className="mb-0 fs-14">Banking Solutoins</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Custom-Service/Curtain.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Curtain</h6>
                                <p className="mb-0 fs-14">Curtain Management</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Custom-Service/Agro-ERP.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Agro ERP</h6>
                                <p className="mb-0 fs-14">Agro ERP Software</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Custom-Service/Cement-ERP.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Cement ERP</h6>
                                <p className="mb-0 fs-14">Cement ERP Software</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Custom-Service/Jewlry.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Jewelry</h6>
                                <p className="mb-0 fs-14">Jewelry Store Management Software</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Custom-Service/Field-Force.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Field Force</h6>
                                <p className="mb-0 fs-14">Field Force Management Software</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Custom-Service/Flight-Booking.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Flight Booking</h6>
                                <p className="mb-0 fs-14">Flight Booking Management Software</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="pills-others" role="tabpanel" aria-labelledby="pills-others-tab">
                        <div className="row g-3">												
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Others/Booking-365.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Booking365</h6>
                                <p className="mb-0 fs-14">Responsive WordPress Appointment Plugin</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Others/Multi-Scheduler.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Multi Scheduler</h6>
                                <p className="mb-0 fs-14">Appointment Booking Multi Booking Plugin</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Others/Travelz.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Travelz</h6>
                                <p className="mb-0 fs-14">Travel WordPress Theme for Tour Agency</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Others/News365.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>News365</h6>
                                <p className="mb-0 fs-14">WordPress Newspaper Theme for Magazine / Blog</p>
                              </div>
                            </a>
                          </div>
                          <div className="col-xl-4 col-md-6">
                            <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                              <div className="d-block">
                                <img src="asset/img/products/Others/Resuma.webp" className="product_icon" alt />
                              </div>
                              <div className="d-block ms-4">
                                <h6>Resuma</h6>
                                <p className="mb-0 fs-14">Resume Portfolio Responsive WordPress Theme</p>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-block position-absolute all_wrapper">
                  <a href="" className="bg_grad bg_grad1 d-block px-4 py-3 text-decoration-none btn_all fs-14">See All Products</a>
                </div>
              </div>
            </li>
            <li className="nav-item dropdown mobile_dropdown">
              <button className="btn nav-link dropdown-toggle" type="button" id="mobileApps" data-bs-toggle="dropdown" aria-expanded="false">
                Mobile Apps
              </button>
              <div className="bg-white border-0 dropdown-menu dropdown-menu-end p-3 shadow mt-3" aria-labelledby="mobileApps">
                <div className="row g-3">
                  <div className="col-lg-6">
                    <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                      <div className="d-block">
                        <img src="asset/img/products/Ecommerce/xIsshue.webp.pagespeed.ic.f5QQ2rI5Hd.webp" className="product_icon" alt />
                      </div>
                      <div className="d-block ms-4">
                        <h6>Isshue Apps</h6>
                        <p className="mb-0 fs-14">Ecommerce Android Apps</p>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                      <div className="d-block">
                        <img src="asset/img/products/Hotel-Restaurant/Bhojon.webp" className="product_icon" alt />
                      </div>
                      <div className="d-block ms-4">
                        <h6>Bhojon Android Apps</h6>
                        <p className="mb-0 fs-14">Restaurant Management Apps</p>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <a href="" className="bg_grad2 p-3 align-items-start d-flex product_link h-100">
                      <div className="d-block">
                        <img src="asset/img/products/Others/News365.webp" className="product_icon" alt />
                      </div>
                      <div className="d-block ms-4">
                        <h6>Newspaper Apps</h6>
                        <p className="mb-0 fs-14">Newspaper Management</p>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-6">
                    <a href="" className="bg_grad p-3 align-items-start d-flex product_link h-100">
                      <div className="d-block">
                        <img src="asset/img/products/m-store.png" className="product_icon" alt />
                      </div>
                      <div className="d-block ms-4">
                        <h6>Mpos Apps</h6>
                        <p className="mb-0 fs-14">Sales ERP Apps</p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li className="nav-item"><Link to="/service" className="nav-link">Service</Link></li>
            <li className="nav-item"><Link to="/" className="nav-link" >Blog</Link></li>
            <li className="nav-item"><Link to="/contact" className="nav-link" >Contact Us</Link></li>
          </ul>
          <div className="d-block d-lg-none menu_call">
            <div className="menu_tray ms-auto" />
            <div className="menu_tray" />
            <div className="menu_tray ms-auto" />
          </div>
        </header>
    </>
  )
}

export default Header