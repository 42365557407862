import React from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';
import { TfiYoutube } from 'react-icons/tfi';

const ServiceContact = () => {
  return (
    <>
    <section className="service-contact py_100 bg_grad">
    <div className="container">
      <div className="row">
        {/*Form Column*/}
        <h3 className="fs-1 fw-bold mb-3"> <span className="text-nikunjerp animate-text"> Contact Me</span></h3>
         
        <div className="form-column col-lg-6 col-xs-12">
        
          <div className="column-inner">
            {/*Contact Form*/}
            <div className="contact-form">
              <form method="post" action="#" id="contact-form" noValidate="novalidate">
                <div className="form-group">
                  <input className="form-control pd3" name="name" id="senderName" placeholder="Full Name" required type="text" />
                </div>
                <div className="form-group">
                  <input className="form-control pd3" name="email" id="senderMail" placeholder="Email Address*" required type="email" />
                </div>
                <div className="form-group">
                  <input className="form-control pd3" name="subject" id="form_subject" placeholder="Subject*" type="text" />
                </div>
                <div className="form-group">
                  <textarea name="message" className="form-control pd3" id="form_message" placeholder="Your Message*" rows={4} defaultValue={""} />
                </div>
                <div className="form-group">
                  <Link to="" className="btn btn-success px-5 py-3">Send Message</Link>
                  
                </div>
              </form>
            </div>
          </div>
        </div>
        {/*Info Column*/}
        <div className="col-lg-6 col-xs-12">
          <div className="ps-0 ps-lg-5">
            <div className="sec-title">
              <h2 className="fw-bold fs-40">Don't Hesitate to <br /> contact with us for any <br /> kind of information</h2>
            </div>
            <div className="text">Call us for query...</div>
            <h3>+91-9034905402</h3>
            {/*Social Icon Four*/}
            <ul className="d-flex list-unstyled mt-4">
              <li><a className="align-items-center d-flex justify-content-center me-2 text-decoration-none text-white social_icon" href="" title="Facebook" target="_blank"><FaFacebookF /></a></li>
              <li><a className="align-items-center d-flex justify-content-center me-2 text-decoration-none text-white social_icon" href="" title="Instagram" target="_blank"><BsInstagram /></a></li>
              <li><a className="align-items-center d-flex justify-content-center me-2 text-decoration-none text-white social_icon" href="" title="Linkedin" target="_blank"><FaLinkedinIn /></a></li>
              <li><a className="align-items-center d-flex justify-content-center me-2 text-decoration-none text-white social_icon" href="" title="Youtube" target="_blank"><TfiYoutube /></a></li>
              <li><a className="align-items-center d-flex justify-content-center me-2 text-decoration-none text-white social_icon" href="" title="Twitter" target="_blank"><FaTwitter /></a></li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default ServiceContact