import React from 'react'
import Whatsapp_Teligram from './SubCompo/Whatsapp_Teligram'
import Header from './Header'
import ServiceContact from './SubCompo/ServiceContact'

const Contact = () => {
  return (
    <>
  <Whatsapp_Teligram />
  <div className="banner_wrapper position-relative ">
    <div className="header_area ">
      <div className="container-lg ">
        {/* Start Navigation */}
        <Header />
      </div>
    </div>
    
    {/* Banner Start*/}
    <div className="main-container py_100 image-container2">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8 text-center text-white">
            <h1 className="page-title fw-bold">Hello. <span className="fw-light">How can we help you?</span></h1>
          </div>
        </div>
        <div className="contact-address">
          <div className="row g-3">
            <div className="col-xl-3 col-sm-6">
              <div className="bg_grad h-100 px-4 py-5">
                <h5> Office Address:</h5>
                Babail Road, Near Chhotu <br /> Ram Chowk, Gyandeep School, 
                <br />
                Panipat, Haryana-132103
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="bg_grad2 h-100 px-4 py-5">
                <h5>Contact No:</h5>
                <br />
                 Mobile No - +91-9034905402
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="bg_grad h-100 px-4 py-5">
                <h5>Email id:</h5>
                <br />
                Email id - info@chiranjeevibhav.org
              </div>
            </div>
            <div className="col-xl-3 col-sm-6">
              <div className="bg_grad2 h-100 px-4 py-5">
                <h5>Careers.</h5>
                Recruiting<br />
                <br />
                Send your resume here: <br />
                <a href="mailto:info@chiranjeevibhav.com">info@chiranjeevibhav.org</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
    <ServiceContact />
  {/* Map Start*/}
  <div className="py_100">
    <div className="container-md">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3476.0807227932746!2d76.98989690920631!3d29.39719454884697!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6a539a1024e22b1b%3A0x982172ea30004de4!2sChiranjeevi%20Bhav!5e0!3m2!1sen!2sin!4v1709205270000!5m2!1sen!2sin" width={1100} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
      <div className="row">
        <div className="col-xl-10 offset-xl-1">
          <div className="align-items-center bg-dark d-xl-flex justify-content-between py-5 rounded-5 join_box mt-5">
            <div className="d-block">
              <h3 className="fs-3 fw-bold mb-2 text-white">Be with Us to Wheel Up Your Revenue</h3>
              <p className="text-white mb-0">We are always ready to serve you. Please share your ideas and values with us.</p>
            </div>
            <div className="d-block mt-4 mt-xl-0">
              <a href="" className="btn btn-success px-5 py-3">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </>

  )
}

export default Contact