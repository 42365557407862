import React from 'react'
import Header from './Header'
import Footer from './Footer'
import ClientsLogo from './SubCompo/ClientsLogo'
import ServiceContact from './SubCompo/ServiceContact'
import IndustryWeServe from './IndustryWeServe'

const Service = () => {
  return (
    <>
<div>
 
  <div className="banner_wrapper position-relative">
    <div className="header_area">
      <div className="container-lg">
        {/* Start Navigation */}
        <Header />
      </div>
    </div>
    <div className="bg-white bottom-0 flex-shrink-0 px-4 position-fixed top-0 sideNav">
      <div className="d-flex justify-content-end w-100">
        <button className="btn close_icon fs-5 mb-3 px-0">✕</button>
      </div>
      <ul className="list-unstyled ps-0 mobileNav">
        <li>
          <button className="btn btn-toggle align-items-center" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="true">
            All products <i className="ti-angle-up transition" />
          </button>
          <div className="collapse show" id="home-collapse" style={{}}>
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-3">
              <li>
                <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#mbusiness-collapse" aria-expanded="true">
                  Business Solution <i className="ti-angle-up transition" />
                </button>
                <div className="collapse" id="mbusiness-collapse" style={{}}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ms-3">
                    <li><a href="" className target="_blank">ERP</a></li>
                    <li><a href="" className target="_blank">HR Manager</a></li>
                    <li><a href="" className target="_blank">M-Store</a></li>
                    <li><a href="" className target="_blank">Wholesale</a></li>
                    <li><a href="" className target="_blank">Chatbot</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#mblock-chain-collapse" aria-expanded="true">
                  Blockchain <i className="ti-angle-up transition" />
                </button>
                <div className="collapse" id="mblock-chain-collapse" style={{}}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ms-3">
                    <li><a href="" className target="_blank">Tradebox</a></li>
                    <li><a href="" className target="_blank">Nishue</a></li>
                    <li><a href="" className target="_blank">ICO-Wallet</a></li>
                    <li><a href="" className target="_blank">Tokenbox</a></li>
                    <li><a href="" className target="_blank">NFTbox</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#mecommerce-collapse" aria-expanded="true">
                  Ecommerce <i className="ti-angle-up transition" />
                </button>
                <div className="collapse" id="mecommerce-collapse" style={{}}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ms-3">
                    <li><a href="" className target="_blank">Isshue</a></li>
                    <li><a href="" className target="_blank">Storefex</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#mhotel-collapse" aria-expanded="true">
                  Hotel &amp; Restaurant <i className="ti-angle-up transition" />
                </button>
                <div className="collapse" id="mhotel-collapse" style={{}}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ms-3">
                    <li><a href="" className target="_blank">Bhojon</a></li>
                    <li><a href="" className target="_blank">Cloud Kitchen</a></li>
                    <li><a href="" className target="_blank">Xain Hotel</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#monline-reservation-collapse" aria-expanded="true">
                  Online Reservation <i className="ti-angle-up transition" />
                </button>
                <div className="collapse" id="monline-reservation-collapse" style={{}}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ms-3">
                    <li><a href="" className target="_blank">VMS</a></li>
                    <li><a href="" className target="_blank">GMS</a></li>
                    <li><a href="" className target="_blank">Flight Booking</a></li>
                    <li><a href="" className target="_blank">Bus365 Apps</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#meducation-collapse" aria-expanded="true">
                  Education <i className="ti-angle-up transition" />
                </button>
                <div className="collapse" id="meducation-collapse" style={{}}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ms-3">
                    <li><a href="" className target="_blank">Edutask</a></li>
                    <li><a href="" className target="_blank">Quizguru</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#mhealth-collapse" aria-expanded="true">
                  Health <i className="ti-angle-up transition" />
                </button>
                <div className="collapse" id="mhealth-collapse" style={{}}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ms-3">
                    <li><a href="" className target="_blank">Hospital Automanager</a></li>
                    <li><a href="" className target="_blank">Hospital</a></li>
                    <li><a href="" className target="_blank">Multi Hospital</a></li>
                    <li><a href="" className target="_blank">Pharmacare</a></li>
                    <li><a href="" className target="_blank">Doctorss</a></li>
                    <li><a href="" className target="_blank">G-Prescription</a></li>
                    <li><a href="" className target="_blank">Clinic365</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#news-collapse" aria-expanded="true">
                  News Portal <i className="ti-angle-up transition" />
                </button>
                <div className="collapse" id="news-collapse" style={{}}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ms-3">
                    <li><a href="" className target="_blank">News365</a></li>
                    <li><a href="" className target="_blank">Osru</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#mtheme-collapse" aria-expanded="true">
                  Theme <i className="ti-angle-up transition" />
                </button>
                <div className="collapse" id="mtheme-collapse" style={{}}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ms-3">
                    <li><a href="" className target="_blank">Travelz</a></li>
                    <li><a href="" className target="_blank">News365</a></li>
                    <li><a href="" className target="_blank">Resuma</a></li>
                  </ul>
                </div>
              </li>
              <li>
                <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#custom-collapse" aria-expanded="true">
                  Custom Service <i className="ti-angle-up transition" />
                </button>
                <div className="collapse" id="custom-collapse" style={{}}>
                  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 ms-3">
                    <li><a href="" className target="_blank">Banking</a></li>
                    <li><a href="" className target="_blank">Curtain</a></li>
                    <li><a href="" className target="_blank">Agro ERP</a></li>
                    <li><a href="" className target="_blank">Jewelry</a></li>
                    <li><a href="" className target="_blank">Field Force</a></li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
        <li className>
          <button className="btn btn-toggle align-items-center rounded collapsed" data-bs-toggle="collapse" data-bs-target="#dashboard-collapse" aria-expanded="false">
            Mobile Apps <i className="ti-angle-up transition" />
          </button>
          <div className="collapse" id="dashboard-collapse" style={{}}>
            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-3">
              <li><a className target="_blank" href="">Ecommerce Android Apps</a></li>
              <li><a className target="_blank" href="">Bhojon Android Apps</a></li>
              <li><a className target="_blank" href="">Newspaper Apps</a></li>
              <li><a className target="_blank" href="">Mpos - Sales ERP Apps</a></li>
            </ul>
          </div>
        </li>
        <li className>
          <a href="" className target="_blank">Services</a>
        </li>
        <li className>
          <a href="" className target="_blank">Blog</a>
        </li>
        <li className>
          <a href="" className target="_blank">Contact Us</a>
        </li>
      </ul>
    </div>
    {/* Banner Start*/}
    <div className="main-container image-container  py_100 bg_service position-relative" >
      <div className="container" >
        <div className="justify-content-center position-relative row text-white" style={{zIndex: 1}}>
          <div className="col-md-9 text-center">
            <h1 className="fw-bold mb-2 page-title text-center">Our Services</h1>
            <h1 className="fs-3 fw-bold mb-4 text-center">Best Offshore Software, Application, Digital &amp; IT Solutions</h1>
            <p className="fs-17">At NikunjERP, we prioritize innovation, reliability, and client satisfaction. Let's accelerate together to your goal with our best software &amp; IT service today.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Platform Start*/}
  <div className="py_100">
    <div className="container-md">
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="text-center mb-5">
            <h2 className="fs-45 fw-bold mb-4 wow fadeInUp">Transform Your Vision Into Reality <span className="text-NikunjERP animate-text">Design Any Customized</span> Software</h2>
            <p className="wow fadeInUp sub_text mx-5" data-wow-delay="0.2s">NikunjERP, Inc. focuses on the exact requirements of the clients. We design and develop the best and most advanced software for all types of businesses.</p>
          </div>
        </div>
      </div>
      <div className="row g-3 mb-5">
        <div className="col-12">
          <h2 className="fw-bold text-style">Software Solution</h2>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="" className="border-0 card px-5 py-5 wow fadeIn bg_grad bgChangeOnHover h-100 product_link text-center" data-wow-delay="0.4s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/offshore-software-development.webp" className alt="Offshore Software Development" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">Offshore Software Development</h5>
              <p className="card-text">We provide complete end-to-end Offshore Software Development such as design development, support, maintenance, tasting etc. So, Build your quality products at a reasonable price with Offshore Software Development services.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="" className="border-0 card px-5 py-5 wow fadeIn bg_grad bgChangeOnHover h-100 product_link text-center" data-wow-delay="0.4s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/custom-software-developmet.webp" className alt="Custom Software Development" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">Custom Software Development</h5>
              <p className="card-text">We are ready to develop any kind of software solution to automate your business operations according to your requirements. Your trusted partner for custom software development services.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="" className="border-0 card px-5 py-5 wow fadeIn bg_grad2 bgChangeOnHover h-100 product_link text-center" data-wow-delay="0.6s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/website-development.webp" className alt="Website Development" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">Website Development</h5>
              <p className="card-text">A website can represent and polish your business identity . We can build and develop it according to the motive of your business.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="" className="border-0 card px-5 py-5 wow fadeIn bg_grad bgChangeOnHover h-100 product_link text-center" data-wow-delay="0.8s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/mobile-app-development.webp" className alt="NikunjERP Mobile App Development" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">Mobile App Development</h5>
              <p className="card-text">Let's hand over your project to our skilled and professional Android and iOS mobile app developers.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="" className="border-0 card px-5 py-5 wow fadeIn bg_grad2 bgChangeOnHover h-100 product_link text-center" data-wow-delay="1s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/e-commerce.webp" className alt="E-Commerce" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">E-Commerce</h5>
              <p className="card-text">From ideation through development, we provide our clients with end-to-end eCommerce customized software solutions.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="cyber-security-service.html" target="_blank" className="border-0 card px-5 py-5 wow fadeIn bg_grad bgChangeOnHover h-100 product_link text-center" data-wow-delay="1.2s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/cyber-security.webp" className alt="Cyber Security" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">Cyber Security</h5>
              <p className="card-text">Secure your website with our NikunjERP cyber security service. You can trust us to give expert guidance and deliver guaranteed results.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="" className="border-0 card px-5 py-5 wow fadeIn bg_grad2 bgChangeOnHover h-100 product_link text-center" data-wow-delay="1s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/it-support.webp" className alt="IT Support" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">IT Support</h5>
              <p className="card-text">Get the right talent for the right place. We offer the best solutions and highly skilled personnel to strengthen your team.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="border-0 card px-5 py-5 wow fadeIn bg_grad h-100 product_link d-flex align-items-center justify-content-center" data-wow-delay="1.2s">
            <a href="" className="btn btn-success px-4 py-3">Get a Quote</a>
          </div>
        </div>
      </div>
      <div className="row g-3">
        <div className="col-12">
          <h2 className="fw-bold text-style">Digital Solution</h2>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="" className="border-0 card px-5 py-5 wow fadeIn bg_grad bgChangeOnHover h-100 product_link text-center" data-wow-delay="0.4s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/digital-marketing-service.webp" className alt="Digital Marketing Service" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">Digital Marketing Service</h5>
              <p className="card-text">Boost your brand's online presence with our expert digital marketing service. It is time to Maximizing visibility, engagement, and growth opportunities for your business.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="video-production-service.html" className="border-0 card px-5 py-5 wow fadeIn bg_grad bgChangeOnHover h-100 product_link text-center" data-wow-delay="0.4s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img//video-production-service.webp" className alt="Video Production Service" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">Video Production Service</h5>
              <p className="card-text">Create your brand stories &amp; reach a larger audience through our Video production service. Increase engagement, and build trust with high-quality video content.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="" className="border-0 card px-5 py-5 wow fadeIn bg_grad2 bgChangeOnHover h-100 product_link text-center" data-wow-delay="0.6s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/cliping-path-service.webp" className alt="Clipping Path service" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">Clipping Path service</h5>
              <p className="card-text">Leave your project to a reliable multiple-clipping service provider like us. We guarantee to provide the best, most efficient, and fastest service.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="" className="border-0 card px-5 py-5 wow fadeIn bg_grad bgChangeOnHover h-100 product_link text-center" data-wow-delay="0.8s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/graphics-design.webp" className alt="Graphics Design" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">Graphics Design</h5>
              <p className="card-text">Get the perfect design and ensure your branding hits the spot with our graphics design service. Remember!! The right colour combination, shapes and words will define your business.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <a href="" className="border-0 card px-5 py-5 wow fadeIn bg_grad2 bgChangeOnHover h-100 product_link text-center" data-wow-delay="1.2s">
            <div className="d-block mb-4 position-relative">
              <img src="img/service-img/blogging-platform.webp" className alt="Blogging Platform" />
            </div>
            <div className="card-body p-0 position-relative">
              <h5 className="card-title mb-3 fw_600">Blogging Platform</h5>
              <p className="card-text">We can develop an SEO-friendly content management system to publish blogs, articles, online news, and so on.</p>
            </div>
          </a>
        </div>
        <div className="col-xl-4 col-md-6">
          <div className="border-0 card px-5 py-5 wow fadeIn bg_grad h-100 product_link d-flex align-items-center justify-content-center" data-wow-delay="1.2s">
            <a href="" className="btn btn-success px-4 py-3">Get a Quote</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Service Start*/}
 <IndustryWeServe />
  {/* faq  */}
  <section className="py_100">
    <div className="container-md">
    <h2 className="fs-1 fw-bold wow fadeInUp text-center mb-4"><span className="animate-text">FAQ</span></h2>
               
      <div className="row justify-content-center">
        <div className="col-lg-8">
          <div className="accordion accordion-flush service-faq" id="accordionFlushExample">
            <div className="accordion-item mb-3">
              <h2 className="accordion-header" id="flush-headingOne">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">How does software customization impact the quality of IT services?</button>
              </h2>
              <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body ps-5"><p>Customised software solutions can significantly improve the effectiveness and efficiency of your business and ensure maximum ROI.</p></div>
              </div>
            </div>
            <div className="accordion-item mb-3">
              <h2 className="accordion-header" id="flush-headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">Why Is Digital Marketing Service Important?</button>
              </h2>
              <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <p>Digital marketing is important because it allows businesses to reach and engage with their target audience effectively online. With the increasing use of the internet and social media, digital marketing provides opportunities to</p>
                  <ul>
                    <li>Build brand awareness</li>
                    <li>Drive traffic</li>
                    <li>Generate leads</li>
                    <li>Ultimately increase sales and revenue.</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3">
              <h2 className="accordion-header" id="flush-headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">Which Company Is Best For IT Support?</button>
              </h2>
              <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <p>Determining the "best" IT support company depends on various factors such as your specific requirements, budget, location, and the scope of services required.</p>
                  <p>However, NikunjERP is the best IT service company in the world. They offer many IT services including</p>
                  <ul>
                    <li>Custom Software Development</li>
                    <li>Website Development</li>
                    <li>Mobile App Development</li>
                    <li>E-Commerce</li>
                    <li>Cyber Security</li>
                    <li>Digital Marketing Service</li>
                    <li>Video Production Service</li>
                    <li>Graphics Design</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3">
              <h2 className="accordion-header" id="flush-headingFour">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">What Is NikunjERP Every Project's Average Cost?</button>
              </h2>
              <div id="flush-collapseFour" className="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <p>It's best to contact NikunjERP directly for a personalized quote based on your specific project needs.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3">
              <h2 className="accordion-header" id="flush-headingFive">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">What are the key factors to consider when selecting the best IT service provider?</button>
              </h2>
              <div id="flush-collapseFive" className="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <p>Factors such as expertise, reputation, customer support, scalability, and pricing are crucial when choosing the best IT service provider.</p>
                </div>
              </div>
            </div>
            <div className="accordion-item mb-3">
              <h2 className="accordion-header" id="flush-headingSix">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">Can You Support Fully Remote Team?</button>
              </h2>
              <div id="flush-collapseSix" className="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                  <p>Yes. We support and use remote tools to accommodate our clients wherever they are.</p>
                  <button type="button" className="btn btn-success px-4 py-3">Learn more</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <ServiceContact />
  {/* Client Logo Start*/}
  <ClientsLogo />
  {/* Client Logo End*/}
  <Footer />
  {/* Back to top button */}
  <a className="gotoTop position-fixed rounded-2 text-center text-decoration-none text-white transition">
    <i className="ti-arrow-up" />
  </a>
  {/* appointment modal open for all page  */}
</div>

    </>
  )
}

export default Service